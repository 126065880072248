import React from 'react';
import DoorOtpForm from './DoorOtpForm';
import { useParams } from 'react-router-dom';

const DoorAccessSection = () => {
    const { phone } = useParams();
    return (
        <div className="section-content-sticky">
            <div className="section-content-wrapper">

                <h2 className="intro-subhead">Door Access</h2>
                <p className="intro-paragraph">with your phone</p>
                <DoorOtpForm bookingPhone={phone} />
                <small>you may unlock up to 15 minutes before your session</small>
                <div className="cta-links w-col">
                    <a href="https://anybirdie.com" className="cta-link left">Back</a>
                    <a href="https://wa.me/+6587727362" className="cta-link">Help</a>
                </div>
            
            </div>
            <div className="section-content-frame-bottom"></div>
        </div>
    );
}

export default DoorAccessSection;