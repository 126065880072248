import React from 'react';

const Navbar = () => {
    return (
        <div className="nav">
            <div className="container">
                <div className="nav-menu">
                </div>
            </div>
        </div>
    );
};

export default Navbar;