import React, { useState, useEffect } from 'react';

const DoorOtpForm = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpPin, setOtpPin] = useState('');
    const [isOtpRequested, setIsOtpRequested] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(true);
    const [resendAttempts, setResendAttempts] = useState(1);   
    const [countdown, setCountdown] = useState(0);
    const [statusLabel, setStatusLabel] = useState('Unlock');
    const COOL_DOWN_TIME = 30;
    const bookingPhone = props.bookingPhone;

    // Phone number validation
    const isValidPhoneNumber = phoneNumber.match(/^[89]\d{7}$/);
    // OTP validation
    const isValidOtp = otpPin.match(/^\d{4}$/) && isOtpRequested;

    useEffect(() => {
        let intervalId;
        if (resendDisabled && countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((countdown) => countdown - 1);
            }, 1000);
        } else if (countdown <= 0) {
            setResendDisabled(false);
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [resendDisabled, countdown]);
    
    const handleUnlockOtp = async() => {
        // API call to send OTP
        const apiEndpoint = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_API_DOOR_UNLOCK}`;
        
        // send login request
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "phone" : bookingPhone, 
                    "requesterPhone": phoneNumber,
                    "otp" : otpPin
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                setStatusLabel(`${data.message}, try again`);
                throw new Error('Network response was not ok');
            }

            setStatusLabel(`${data.message}`);
        } catch (error) {
            console.error('Failed to request OTP:', error);
        }
    };

    const handleRequestOtp = async () => {
        if (!isValidPhoneNumber) return;
        
        // Toggle visibility and change button text
        setIsOtpRequested(true);
        setResendDisabled(true);
        setCountdown(COOL_DOWN_TIME);
        setResendAttempts(resendAttempts + 1);

        // API call to send OTP
        const apiEndpoint = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_API_REQUEST_OTP}`;
        
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "phone" : phoneNumber }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

        } catch (error) {
            console.error('Failed to request OTP:', error);
        }
    };

    return (
        <div>
            <label>
                Phone Number
                <input 
                    className="w-input" 
                    type="tel"
                    name="phoneNumber" 
                    placeholder={phoneNumber}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </label>

            <label>
                <button 
                    type="button"
                    className="button w-button w-col" 
                    onClick={handleRequestOtp}
                    disabled={!isValidPhoneNumber || resendAttempts >= 3 || resendDisabled}>
                    { resendDisabled ? `Resend in ${countdown}s` : resendAttempts >= 3 ? 'Max attempts reached' : 'Request OTP'}
                </button>
            </label>

            {isOtpRequested && (
                <div>
                    <label>
                        OTP
                        <input 
                            className="w-input" 
                            type="number" 
                            name="otpPin" 
                            placeholder='XXXX'
                            value={otpPin}
                            onChange={(e) => setOtpPin(e.target.value)}
                        />
                    </label>
            
                    <label>
                        <button 
                            type="Unlock" 
                            className="button w-button w-col" 
                            onClick={handleUnlockOtp}
                            disabled={!isValidOtp}>
                            {statusLabel}
                        </button>
                    </label>
                </div>
            )}
        </div>
    );
}

export default DoorOtpForm;