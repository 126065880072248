import React from 'react';

const NavbarProduct = () => {
    return (
        <div className="product-nav">
            <div className="container">
            <div className="product-nav-content">
                <a href="#" className="product-nav-title">Unlock Door</a>
            </div>
            </div>
        </div>
    );
};

export default NavbarProduct;