import { Routes, Route } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import NavbarProduct from './components/NavbarProduct';
import DoorAccessSection from './screens/DoorAccessSection';

function App() {
  
  const userData = {
      "phone":"",
      "email":"lucas@11a0.com",
      "firstname":"lucas",
      "lastname":"liao",
      "totalBookings":0,
      "credits": 0,
      "points": 0,
      "tier":"regular"
  }

  return (
    <div className="App">
      <div className="site-body">
        <Navbar/>
        <NavbarProduct/>
        
        <div className={`slider-container ${userData.tier}`}>
          <Routes>
            <Route path='/authenticate/:phone' element={<DoorAccessSection/>} />
          </Routes>
        </div>
        
      </div>
    </div>
  );
}

export default App;
